import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Flex from '../components/Flex'
import Image from '../components/Image'
import PressList from '../components/PressList'

const propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

const Presse = ({ data }) => {
  return (
    <Layout>
      <SEO title="Presse" />

      <Container smallplus>
        <p sx={{ mb: 2 }}>Presse</p>
        <h1 sx={{ hyphens: `manual` }}>Unsere Mandate in der Presse</h1>
      </Container>

      <Flex
        large
        sx={{
          flexDirection: `column`,
          p: 0,
          height: [`240px`, `320px`, `400px`, `400px`],
        }}
      >
        <Image
          fluid={data.presse01.childImageSharp.gatsbyImageData}
          sx={{
            bg: `gray.5`,
            height: [`240px`, `320px`, `400px`, `400px`],
          }}
        />
      </Flex>

      <Container sx={{ pb: 0 }}>
        <PressList pressEntries={data.pressEntries.edges} />
      </Container>
    </Layout>
  )
}

Presse.propTypes = propTypes

export default Presse

export const query = graphql`
  {
    pressEntries: allSanityPress(
      filter: { active: { eq: true }, mediaSource: { mediaName: { ne: null } } }
      limit: 200
      sort: { fields: [releaseDate], order: [DESC] }
    ) {
      edges {
        node {
          headline
          releaseDate(locale: "DE", formatString: "DD.MM.YYYY")
          pressLink
          active
          mediaSource {
            mediaName
            mediaLogo {
              asset {
                gatsbyImageData(width: 40, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    presse01: file(relativePath: { eq: "rav/matt-chesin-xDtiCFu_Z3s-unsplash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2400, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
    presse02: file(relativePath: { eq: "rav/matt-chesin-xDtiCFu_Z3s-unsplash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
  }
`
